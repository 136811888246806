
/**
 * @class ReportInaccuracyStore
 *
 * Used for reporting inaccurate data in the domain lookup result
 */
import axios from '@/lib/Axios';
import _get from 'lodash/get';

const baseURL = '/api/rdap/report';

/**
 * Initial state
 * @return {object} state
 */
const initialState = () => ({
    data: null,
    errors: null,
    successMessages: null,
    fields: {},
    report_reason: "",
});

const ReportInaccuracyStore = () => ({
    namespaced: true,
    state: initialState(),
    mutations: {
        /**
         * Set errors
         * @param {object} state
         * @param {object} payload
         */
        error(state, payload) {
            state.errors = _get(payload, 'response.data.response_text', 'Unknown error');
        },
        /**
         * Clear errors
         * @param {object} state
         */
        clearErrors(state) {
            state.errors = null;
        },
        /**
         * Set success message
         * @param {object} state
         */
        success(state) {
            state.successMessages = "Thank you, we have received your report of inaccurate registration data (whois). In accordance with the 2013 RAA's Whois Accuracy Program Specification, we will reach out to the registrant to confirm or correct their data; if the data are neither confirmed nor corrected, the domain will be suspended. If the data are corrected, you will see the updated information here following that change. If the data are confirmed, no further action will be taken."
        },
        /**
         * Clear success messages
         * @param {object} state
         */
        clearSuccessMessages(state) {
            state.successMessages = null;
        },
        /**
         * Reset state except success message
         * @param {object} state
         */
        reset(state) {
            state.fields = {};
            state.data = null
            state.errors = null;
            state.nazca_rdap = "";
        },
        /**
         * Set fields
         * @param {object} state
         * @param {object} payload
         */
        setFields(state, payload) {
            state.fields = payload;
        }
    },
    actions: {
        /**
         * report inaccurant lookup back to tucows compliance
         * @param {Object} data
         * @param {string} data.brand    brand of the domain
         * @param {string} data.domain   Domain being looked up
         * @param {string} data.fields   Fields to be reported
         */
        reportData({ commit, rootState }, { brand, domain, fields, report_reason }) {
            return axios
                .post(`${baseURL}/${brand}/domain/${domain}`, {
                    fields,
                    report_reason,
                }, {
                    headers: _get(rootState, 'user.headers'),
                    isLoading: true
                })
                .then((res) => {
                    commit('success')
                    commit("reset")
                })
                .catch((err) => {
                    commit('error', err);
                });
        },
        /**
         * Clear errors
         * @param {object} commit
         */
        clearErrors({ commit }) {
            commit('clearErrors');
        },
        /**
         * Clear success messages
         * @param {object} commit
         */
        clearSuccessMessages({ commit }) {
            commit('clearSuccessMessages');
        },
        /**
         * add/update fields to store
         * @param {object} commit
         * @param {object} state		Store's state
         * @param {string} roleData	    Role of the contact data being reported
         * @param {string} newFields	newfields to be reported
         */
        addFields({ commit, state }, { roleData, newFields }) {
            state.fields[roleData] = newFields
            commit('setFields', state.fields)
        }
    }
});

export default ReportInaccuracyStore;
