var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showError,
          expression: "showError",
        },
      ],
      staticClass: "notification error",
    },
    [
      _vm._l(_vm.errorList, function (message, index) {
        return _c("div", { key: `message${index}`, staticClass: "message" }, [
          _vm._v("\n\t\t" + _vm._s(message) + "\n\t"),
        ])
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close material-icons",
          attrs: { "aria-label": "Close" },
          on: { click: _vm.close },
        },
        [_vm._v("\n\t\tclose\n\t")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }