/**
 * @class CommonStore
 * 
 * Display success/loading 
 */
import _assignIn from 'lodash/assignIn';
import _get from 'lodash/get';

/**
 * Initial common store to holding loading state from api calls and success message
 * @return {object} store
 */
const initialState = () => ({
	loading: false,
	success: false,
	successMessage: null
});

const mutations = {
	/**
	 * Error
	 * @param {object} state 
	 */
	error(state) {
		state.success = false;
		state.loading = false;
	},
	/**
	 * Success start
	 * @param {object} state
	 * @param {string} message
	 */
	successStart(state, {message}) {
		state.loading = false;
		state.success = true;
		state.successMessage = message;
	},
	/**
	 * Success complete
	 * @param {object} state 
	 */
	successComplete(state) {
		state.success = false;
	},
	/**
	 * Loading started
	 * @param {object} state
	 */
	loadingStart(state) {
		state.success = false;
		state.successMessage = null;
		state.loading = true;
	},
	/**
	 * Loading finished
	 * @param {object} state 
	 */
	loadingComplete(state) {
		state.loading = false;
	},
	/**
	 * Clear errors
	 */
	clearErrors(state) {
		state.loading = false;
		state.success = false;
		state.successMessage = null;
	},
	/**
	 * 
	 * Reset state
	 * @param {object} state 
	 */
	reset(state) {
		_assignIn(state, initialState());
	}
};

const actions = {
	/**
	 * Show success
	 * @param {object} commit 
	 * @param {string} message   success message 
	 */
	showSuccess({commit}, data) {
		const message = _get(data, 'message');
		commit('successStart', {message});
	},
	/**
	 * Hide success
	 * @param {object} commit
	 */
	hideSuccess({commit}) {
		commit('successComplete');
	},
	/**
	 * Show loading
	 * @param {object} commit 
	 */
	showLoading({commit}) {
		commit('loadingStart');
	},
	/**
	 * Hide loading
	 * @param {object} commit 
	 */
	hideLoading({commit}) {
		commit('loadingComplete');
	},
	/**
	 * Clear errors
	 * @param {object} commit
	 */
	clearErrors({commit}) {
		commit('clearErrors');
	}
};

const CommonStore = () => ({
	namespaced: true,
	state: initialState(),
	mutations: mutations,
	actions: actions
});

export default CommonStore