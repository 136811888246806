var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "settings",
      attrs: { tabindex: "0" },
      on: {
        keyup: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            )
              return null
            $event.preventDefault()
            return _vm.handleUp.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown",
              ])
            )
              return null
            $event.preventDefault()
            return _vm.handleDown.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.handleEsc.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.handleToggle.apply(null, arguments)
          },
        ],
        keydown: function ($event) {
          if (!$event.shiftKey) return null
          $event.preventDefault()
          return _vm.handleShift.apply(null, arguments)
        },
        click: _vm.handleToggle,
      },
    },
    [
      _c("div", { staticClass: "user" }, [
        _vm.isLoggedIn ? _c("span", [_vm._v(_vm._s(_vm.username))]) : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "material-icons link",
            attrs: { tabindex: "0", role: "button", title: _vm.fullName },
          },
          [_vm._v("\n\t\t\taccount_box\n\t\t")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "nav",
        { class: _vm.menuCSS, attrs: { role: "menu" } },
        [
          _c(
            "router-link",
            {
              attrs: {
                role: "menuitem",
                to: "user-account-info",
                tabindex: "0",
              },
              nativeOn: {
                mouseover: function ($event) {
                  return _vm.handleMouseover.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                "\n\t\t\t" + _vm._s(_vm.i18n.t("View Account Info")) + "\n\t\t"
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              attrs: { role: "menuitem", to: "password-change", tabindex: "0" },
              nativeOn: {
                mouseover: function ($event) {
                  return _vm.handleMouseover.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                "\n\t\t\t" + _vm._s(_vm.i18n.t("Change Password")) + "\n\t\t"
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "link",
              attrs: {
                href: "javascript:void(0)",
                role: "menuitem",
                tabindex: "0",
              },
              on: {
                blur: _vm.blurLast,
                mouseover: _vm.handleMouseover,
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleOnClickLogout.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.i18n.t("Sign Out")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }