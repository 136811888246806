var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("Error"),
      _vm._v(" "),
      _c("SuccessMessage"),
      _vm._v(" "),
      _c("Success", {
        attrs: {
          show: _vm.showSuccessAlert,
          "success-message": _vm.successMessageAlert,
        },
        on: { "update:show": _vm.handleChangeSuccess },
      }),
      _vm._v(" "),
      _c("Loading", {
        attrs: { show: _vm.showLoading },
        on: { "update:show": _vm.handleChangeLoading },
      }),
      _vm._v(" "),
      [
        _c("AppHeader"),
        _vm._v(" "),
        _vm.showFeature ? _c("router-view") : _vm._e(),
        _vm._v(" "),
        _vm.showNotFound ? _c("NotFound") : _vm._e(),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }