import {createApp} from './app';
import _get from 'lodash/get';
import { datadogRum } from '@datadog/browser-rum';

const app_version = require('./config/app_version');

const BUILD_IMAGE = app_version.BUILD_IMAGE || 'TEST';
const NODE_ENV = process.env.NODE_ENV || 'dev';
// Datadog RUM
datadogRum.init({
	applicationId: '4cc6585d-bd00-4974-9301-9858ebfc5c73',
	clientToken: 'pub5510d544a435eaff7ffeb2fadebb878e',
	site: 'datadoghq.com',
	service: 'taco-nazca',
	env: NODE_ENV,
	version:BUILD_IMAGE,
	sampleRate: 100,
})

// create app
const {app, router, store} = createApp();

// prime the store with server-initialized state.
// the state is determined during SSR and inlined in the page markup.
if (window.__INITIAL_STATE__) {
	store.replaceState(window.__INITIAL_STATE__);
}

// mount app
router.onReady(() => {
	router.beforeResolve((to, from, next) => {
		if (_get(store, 'getters.hasErrors')) {
			store.dispatch('clearErrors', null, {root: true});
		}

		next();
	});

	app.$mount('#app');
});
