<template>
	<header class="header">
		<div class="header-container">
			<router-link
				to="/"
				class="title"
			>
				{{ i18n.t('Tiered Access') }}
			</router-link>
			<UserMenu
				v-if="isLoggedIn && hasAcceptedTerms"
			/>
		</div>
	</header>
</template>

<script>
import {mapGetters} from 'vuex';
import UserMenu from './UserMenu.vue';

export default {
	name: 'AppHeader',
	components: {UserMenu},
	computed: {
		...mapGetters({
			'isLoggedIn': 'user/isLoggedIn',
			'hasAcceptedTerms': 'user/hasAcceptedTerms'
		})
	}
};
</script>

<style lang="less">
@import 'theme';


.header-container {
	max-width: @mainMaxWidth;
	margin: 0 auto;
}

.header {
	position: fixed;
	top: 0;
	z-index: 1;
	width: 100%;
	color: @black;
	background: @white;
	border-bottom: 1px solid @border-colour;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);

	.header-container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		height: @headerHeight;
	}

	.title {
		flex: 0 1 auto;
		padding: 0 @p20px;
		font-size: @f20px;
		font-weight: 700;
		color: @black;
		text-transform: uppercase;
		border: none;
		.cursor(pointer);
	}
}
</style>
