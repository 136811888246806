/**
 * @class AxiosInstance 
 * Create axios instance for making api calls. It can trigger show/hide loading and cancel all api calls
 */
import 'es6-promise/auto';
import axios from 'axios';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _set from 'lodash/set';

const Axios = axios.create({proxy: {port: process.env.NODE_SERVER_PORT} });

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

// Intercept request calls 
Axios.interceptors.request.use((config) => {
	if (_get(config, 'isLoading')) {
		const store = Axios.store;
		store.dispatch('common/showLoading', null, {root: true});
	}

	config.cancelToken = source.token;
	return config;
}, (error) => {
	if (_get(error, 'config.isLoading')) {
		const store = Axios.store;
		store.dispatch('common/hideLoading', null, {root: true});
	}
	return Promise.reject(error);
});

// Response calls for success and errors
Axios.interceptors.response.use((response) => {
	const store = Axios.store;
	if (_get(response, 'config.isLoading')) {
		store.dispatch('common/hideLoading', null, {root: true});
	}

	return response;
}, (error) => {
	const store = Axios.store;
	const router = Axios.router;
	const context = Axios.context;

	console.log(error);

	if (_get(error, 'config.isLoading')) {
		store.dispatch('common/hideLoading', null, {root: true});
	}

	const statusId = _get(error, 'response.status');
	const responseText = _get(error, 'response.data.response_text') || '';
	const missingHeaders = responseText.match(/Missing authentication information in header|Operations Cancelled/);
	const noAuth = _get(error, 'config.noAuth');

	// NoAuth set true, then no need to check session 
	if (noAuth) {
		return Promise.reject(error);
	}

	// Unauthorized 401, then redirect to login page
	if (_includes([401], statusId) || missingHeaders) {
		_set(context, 'res.cookies.AUTH_HEADERS', '');
		store.dispatch('user/logout', null, {root: true});
		// Cancel all ajax calls
		source.cancel('Operations cancelled');
		router.go('/login');
	}

	return Promise.reject(error);
});

export default Axios;
