var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "header-container" },
      [
        _c("router-link", { staticClass: "title", attrs: { to: "/" } }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.i18n.t("Tiered Access")) + "\n\t\t"),
        ]),
        _vm._v(" "),
        _vm.isLoggedIn && _vm.hasAcceptedTerms ? _c("UserMenu") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }