<template>
	<div
		class="settings"
		tabindex="0"
		@keyup.up.prevent="handleUp"
		@keyup.down.prevent="handleDown"
		@keyup.esc="handleEsc"
		@keyup.enter.stop.prevent="handleToggle"
		@keydown.shift.prevent="handleShift"
		@click="handleToggle"
	>
		<div class="user">
			<span v-if="isLoggedIn">{{ username }}</span>
			<button
				class="material-icons link"
				tabindex="0"
				role="button"
				:title="fullName"
			>
				account_box
			</button>
		</div>
		<nav
			role="menu"
			:class="menuCSS"
		>
			<router-link
				role="menuitem"
				to="user-account-info"
				tabindex="0"
				@mouseover.native="handleMouseover"
			>
				{{ i18n.t('View Account Info') }}
			</router-link>
			<router-link
				role="menuitem"
				to="password-change"
				tabindex="0"
				@mouseover.native="handleMouseover"
			>
				{{ i18n.t('Change Password') }}
			</router-link>
			<a
				href="javascript:void(0)"
				class="link"
				role="menuitem"
				tabindex="0"
				@blur="blurLast"
				@mouseover="handleMouseover"
				@click.prevent="handleOnClickLogout"
			>{{ i18n.t('Sign Out') }}</a>
		</nav>
	</div>
</template>

<script>
/**
	 * @class UserMenu
	 *
	 * Menu on the top right corner which include account settings and logout.
	 */
import {mapActions, mapGetters} from 'vuex';
import _get from 'lodash/get';
import Filters from 'tucows-ui-misc/src/Filters';

export default {
	name: 'UserMenu',
	/**
	 * @ignore
	 */
	data() {
		return {
			menuItems: null,
			menuItemsArray: null,
			showMenu: false
		};
	},
	computed: {
		...mapGetters({
			'isLoggedIn': 'user/isLoggedIn',
			'username': 'user/username',
			'fullName': 'user/fullName'
		}),
		/**
			 * Determine if menu is open or close
			 */
		menuCSS() {
			return {open: this.showMenu};
		}
	},
	watch: {
		/**
		 * Watch if user is logged in/out
		 * @param {boolean} newVal   logged in
		 */
		isLoggedIn(newVal) {
			this.showMenu = newVal;
		}
	},
	/**
		 * Initialize menu list
		 */
	mounted() {
		this.init();
	},
	/**
		 * Listen to click outside the menu
		 */
	created() {
		if (Filters.isWindow()) {
			document.addEventListener('click', this.clickedOutside);
		}
	},
	/**
		 * Remove listener before destroying
		 */
	beforeDestroy() {
		if (Filters.isWindow()) {
			document.removeEventListener('click', this.clickedOutside);
		}
	},
	methods: {
		...mapActions({'userLogout': 'user/logout'}),
		/**
			 * Get all menu items and put in array for easy keyboard movements such as move up/down, esc, etc..
			 */
		init() {
			this.menuItems = this.$el.querySelectorAll("nav [tabindex='0']");
			this.menuItemsArray =  Array.prototype.slice.call(this.menuItems);
		},
		/**
			 * Handle logout
			 */
		handleOnClickLogout() {
			this.userLogout();
			this.$router.push({path: '/login'});
		},
		/**
			 * Open/Close menu
			 */
		handleToggle() {
			this.showMenu = !this.showMenu;
		},
		/**
			 * Key presses moves item selection up or down (keyup, keydown, keypress)
			 * @param {string} type  Type of keycode
			 * @param {object} ev    Event
			 */
		keyChange({type, ev}) {
			const index = this.getIndex({
				type,
				ev
			});
			const item = _get(this.menuItems, index);
			item && item.focus();
		},
		/**
			 * Get the next menu item from moving up/down, mouseover or blur.
			*/
		getIndex({type, ev}) {
			const target = _get(ev, 'target');
			const currentIndex = this.menuItemsArray.indexOf(target);
			const max = this.menuItemsArray.length - 1;

			let nextIndex = {
				'up': currentIndex === 0 ? max : currentIndex - 1,
				'down': currentIndex === max ? 0 :  currentIndex + 1,
				'blurLast': 0,
				'mouseover': currentIndex
			};

			return nextIndex[type];
		},
		/**
			 * User pressed up key, go up menu list
			 * @param {object} ev Event
			 */
		handleUp(ev) {
			this.keyChange({
				type: 'up',
				ev: ev
			});
		},
		/**
			 * User pressed down key, go down menu list
			 * @param {object} ev Event
			 */
		handleDown(ev) {
			this.keyChange({
				type: 'down',
				ev: ev
			});
		},
		/**
			 * User pressed esc key, hide menu
			 * @param {object} ev Event
			 */
		handleEsc() {
			this.showMenu = false;
		},
		/**
			 * User pressed shift + tab key, go up menu list
			 * @param {object} ev Event
			 */
		handleShift(ev) {
			const key = _get(ev, 'key');
			const hasShiftTab =  key === 'Tab';

			if (hasShiftTab) {
				this.keyChange({
					type: 'up',
					ev: ev
				});
			}
		},
		/**
			 * Blur on the last field, go to first menu item
			 * @param {object} ev Event
			 */
		blurLast(ev) {
			this.keyChange({
				type: 'blurLast',
				ev: ev
			});
		},
		/**
			 * Clicked outside menu list
			 * @param {object} ev Event
			 */
		clickedOutside(ev) {
			const target = _get(ev, 'target');
			const outside = !this.$el.contains(target);
			if (outside) {
				this.showMenu = false;
			}
		},
		/**
			 * Focus on the current menu item
			 * @param ev  Event
			 */
		handleMouseover(ev) {
			this.keyChange({
				type: 'mouseover',
				ev
			});
		}
	}
};
</script>

<style lang="less" scoped>
@import 'theme';


nav[role="menu"] {
	position: absolute;
	right: @p20px;
	display: none;
	flex-direction: column;
	background: @white;
	border: 1px solid @borderColour;

	&.open {
		right: 0;
		display: flex;
		min-width: 10em;
	}

	> [role="menuitem"] {
		padding: calc(@p20px / 2) calc(@p20px / 2) calc(@p20px / 4);
		color: @grey3;

		&:last-child {
			padding-bottom: calc(@p20px / 2);
		}

		&:focus,
		&:hover {
			color: @grey5;
		}
	}
}

.settings {
	position: relative;
	outline: none;

	.user {
		display: flex;
		align-items: center;

		button {
			padding-right: 0;
			padding-left: @p20px;
			font-size: @f38px;
			color: @grey3;
			outline: none;

			// Firefox remove outline
			&::-moz-focus-inner {
				border: 0;
			}
		}
	}
}
</style>
