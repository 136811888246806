var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "notification success" },
        [
          _vm._l(_vm.messageList, function (message, index) {
            return _c(
              "div",
              { key: `message${index}`, staticClass: "message" },
              [_vm._v("\n    " + _vm._s(message) + "\n  ")]
            )
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "close material-icons",
              attrs: { "aria-label": "Close" },
              on: { click: _vm.close },
            },
            [_vm._v("\n    close\n  ")]
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }