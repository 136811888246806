<template>
	<div
		v-show="showError"
		class="notification error"
	>
		<div
			v-for="(message, index) in errorList"
			:key="`message${index}`"
			class="message"
		>
			{{ message }}
		</div>
		<button
			aria-label="Close"
			class="close material-icons"
			@click="close"
		>
			close
		</button>
	</div>
</template>

<script>
/**
 * @class Error
 *
 * Display errors as a notification on the top of page
 */
import {mapActions, mapGetters} from 'vuex';
import _get from 'lodash/get';

export default {
	name: 'Error',
	/**
	 * @ignore
	 */
	data() {
		return {errorList: this.errors};
	},
	computed: {
		...mapGetters({errors: 'errors'}),
		/**
		 * Whether there are errors
		 * @return {boolean}  has errors
		 */
		showError() {
			const length = _get(this.errorList, 'length');
			return Boolean(length);
		}
	},
	watch: {
		/**
		 * Watch for changes in errors
		 */
		errors(newVal) {
			this.errorList = newVal;
		}
	},
	methods: {
		...mapActions({clearErrors: 'clearErrors'}),
		/**
		 * Close error popup and clear all errors
		 */
		close() {
			this.clearErrors();
		}
	}
};
</script>

<style lang="less" scoped>
@import "common";

.notification {
	position: fixed;
	top: 8px;
	right: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	max-width: 54.857em; //768px;
	padding: 0.857em; // 12px
	margin: 0 auto;
	font-size: 14px;
	font-weight: normal;
	line-height: normal;
	color: @black;
	background: @white;
	border-radius: 4px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);

	&.error {
		padding: 0.857em; // 12px
		border-left: 20px solid @alert;
	}

	.message {
		padding: 0 1em;
		font-size: 14px;
		border-radius: 4px;
	}

	.close {
		position: absolute;
		top: 0;
		right: 0;
		color: @border-colour;
	}
}
</style>
