/**
 * @class UserStore
 *
 * Used for user data and session.
 */
import axios from '@/lib/Axios';
import _get from 'lodash/get';
import Cookie from 'tucows-ui-misc/src/Cookie';
import Filters from 'tucows-ui-misc/src/Filters';
import querystring from 'querystring';
import _merge from 'lodash/merge';
import _pickBy from 'lodash/pickBy';
import _identity from 'lodash/identity';
import _find from 'lodash/find';
import _trim from 'lodash/trim';

const baseURL = '/api/rdap/external';
//This is to update the error notices with a new ones
const errors = {
	'invalid password': 'Incorrect password. Please click "Forgot Password" below if you have forgotten your password.',
	'others': ' is not an active account.'
}
/**
 * Return initial state. Used for logout and session timed out
 */
const initialState = () => ({
	data: null,
	headers: Filters.isWindow() ? JSON.parse(Cookie.get('AUTH_HEADERS')) : null,
	errors: null,
	details: null
});

/**
 * @class UserStore
 *
 * Display user information and determine if user is logged in or not
 */
const UserStore = () => ({
	namespaced: true,
	state: initialState(),
	getters: {

		isLoggedIn: (state) => Boolean(_get(state, 'headers')),
		/**
		 * User must accept terms during login
		 */
		hasAcceptedTerms: (state) => _get(state, 'details.rdap.terms_accepted', false),
		/**
		 * Check if user has the permission to see do not disclose box
		 */
		hasPermission: (state) => _get(state, 'details.rdap.opt_out_permission', false),

		/**
	 * Get username from header cookies
	 * @return {string} username
	 */
		username: (state) => {
			const fields = _get(state, 'headers.X-SRS-Auth-User', '').split(';');
			const field = _find(fields, (f) => f.indexOf('username') > -1) || '';
			const username = field.split('=').pop();

			return username;
		},
		/**
	 * Get the list of brands in rdap metadata
	 * @return {array} brand
	 */
		brands: (state) => _get(state, 'details.rdap.profile.data.brands'),
		/**
	 * Get the full name
	 * @return {string} full name
	 */
		fullName: (state) => {
			const firstName = _trim(_get(state, 'details.first_name', ''));
			const lastName = _trim(_get(state, 'details.last_name', ''));
			return _trim(`${firstName} ${lastName}`);
		},

		/**
		 * Get user info/details
		 * @return {object} user data
		 */
		userDetails: (state) => _get(state, 'details.rdap.contact'),

		/**
		 * Get user email
		 * @return {object} email
		 */
		userEmail: (state) => _get(state, 'details.email'),
	},
	mutations: {
	/**
	 * Set headers
	 * @param {object} state
	 * @param {object} headers
	 */
		setHeaders(state, headers) {
			state.headers = headers;
		},
		/**
	 * User authenticated
	 * @param {object} state
	 * @param {object} headers   headers
	 */
		authenticated(state, {headers}) {
			Cookie.set('AUTH_HEADERS', JSON.stringify(headers));
			state.headers = headers;
			state.errors = null;
		},
		/**
	 * Updated user details
	 * @param {object} state
	 * @param {object} payload
	 */
		updated(state, payload) {
			state.errors = null;
			state.details = _get(payload, 'data.object_data');
		},
		/**
	 * Set errors
	 * @param {object} state
	 * @param {object} payload
	 */
		error(state, payload) {
			state.errors = _get(payload, 'response.data.response_text', 'Unknown error');
		},
		/**
	 * Updated current password
	 * @param {object} state
	 */
		updatePasswordSuccess(state) {
			state.errors = null;
		},
		/**
	 * Clear errors
	 * @param {object} state
	 */
		clearErrors(state) {
			state.errors = null;
		},
		/**
	 * Reset state
	 * @param {object} state
	 */
		reset(state) {
			Cookie.remove('AUTH_HEADERS');
			_merge(state, initialState());
		}
	},
	actions: {
	/**
	 * User request login
	 * @param {object} commit			mutate state
	 * @param {string} username			username
	 * @param {string} password 		password
	 */
		login({commit}, {username, password})	{
			const data = {
				username,
				password
			};
			const url = `/api/no-auth/user/nazca_login`;

			return axios.post(url, querystring.stringify(data), {
				isLoading: true,
				noAuth: true,
				headers: {'REALM': 'rdap-external'}
			})
				.then((res) => {
					const authHeaders = _get(res, 'data.object_data');
					return commit('authenticated', {headers: authHeaders});
				})
				.catch((err) => {
					//This is to update the error notices with a new ones
					if(err != undefined)
						if(err.response.data.response_text == 'invalid password')
							err.response.data.response_text = errors['invalid password']
						else
							err.response.data.response_text = username + errors['others']

					commit('error', err)
				});
		},
		/**
	 * Logout for user
	 * @param {object} dispatch  Dispatch to store
	 */
		logout({dispatch}) {
			dispatch('clearErrors', null, {root: true});
			dispatch('reset', null, {root: true});
		},
		/**
	 * Update user details
	 * @param {object} commit
	 * @param {object} getters
	 * @param {object} status		Store's state
	 * @param {string} name			Username
	 * @param {string} password		Password
	 * @param {string} new_password	New Password
	 */
		update({commit, getters, state}, {new_password, password})	{
			const data = _pickBy({
				password,
				new_password
			}, _identity);

			const username = _get(getters, 'username');

			return axios
				.put(`${baseURL}/user_password/${username}`, data, {
					isLoading: true,
					headers: _get(state, 'headers')
				})
				.then((res) => {
					commit('updated', res);
				})
				.catch((err) => {
					commit('error', err);
				});
		},
		/**
	 * Get user details
	 * @param {object} commit		mutate state
	 */
		getUserByName({commit, state, getters}, params = {isLoading: false}) {
			const username = _get(getters, 'username');
			const isLoading = _get(params, 'isLoading');

			return axios
				.get(`${baseURL}/users/${username}`, {
					headers: _get(state, 'headers'),
					isLoading: isLoading
				})
				.then((res) => {
					commit('updated', res);
				})
				.catch((err) => {
					commit('error', err);
				});
		},
		/**
		 * Password reset
		 * @param {object} commit		mutate state
		 * @param {string} token 		Token
		 * @param {string} username 	Username
		 * @param {string} password 	Password
		 */
		resetPassword({commit}, {token, username, password}) {
			const data = {
				name: username,
				token,
				password
			};

			return axios
				.post(`/api/rdap/no-auth/external/users/resetpassword`, data, {noAuth: true})
				.then((res) => {
					commit('updated', res);
				})
				.catch((err) => {
					commit('error', err);
				});
		},
		/**
	 * Clear errors
	 * @param {object} commit
	 */
		clearErrors({commit}) {
			commit('clearErrors');
		}
	}
});

export default UserStore;
