import _get from 'lodash/get';
import _compact from 'lodash/compact';

/**
 * @class RootStore
 *
 * Manages all the error messages for displaying or clearing
 */
const RootStore = () => ({
	getters: {
		/**
		 * Whether there is an error
		 * @return {boolean}  has errors
		 */
		hasErrors: (state, getters) => Boolean(_get(getters, 'errors.length')),
		/**
		 * A list of errors
		 * @return {array}  errors
		 */
		errors: (state) => {
			const modules = Object.keys(state);
			const list = [];
			for (let i = 0; i < _get(modules, 'length', 0); i++) {
				const module = modules[i];
				const errors = _get(state, [module, 'errors'], '');
				list.push(errors);
			}

			return _compact(list);
		},
		/**
		 * Whether there is an success message
		 * @return {boolean}
		 */
		hasSuccessMessages: (state, getters) => Boolean(_get(getters, 'successMessages.length')),
		/**
		 * A list of success messages
		 * @return {array}  messages
		 */
		successMessages: (state) => {
			const modules = Object.keys(state);
			const list = [];
			for (let i = 0; i < _get(modules, 'length', 0); i++) {
				const module = modules[i];
				const successMessages = _get(state, [module, 'successMessages'], '');
				list.push(successMessages);
			}

			return _compact(list);
		}
	},
	actions: {
		/**
		 * Clear all store errors
		 * @param {object} commit   Dispatch mutation to store
		 * @param {object} state     State of stores
		 */
		clearErrors({commit, state}) {
			const modules = Object.keys(state);

			for (let i = 0; i < modules.length; i++) {
				const module = modules[i];

				// Need to clear error when changing routes
				if (Object.prototype.hasOwnProperty.call(state[module], 'errors')) {
					commit(`${module}/clearErrors`, null, {root: true});
				}
			}
		},
		/**
		 * Clear all store errors
		 * @param {object} commit   Dispatch mutation to store
		 * @param {object} state     State of stores
		 */
		clearSuccessMessages({ commit, state }) {
			const modules = Object.keys(state);
			for (let i = 0; i < modules.length; i++) {
				const module = modules[i];
				if (Object.prototype.hasOwnProperty.call(state[module], 'successMessages')) {
					commit(`${module}/clearSuccessMessages`, null, { root: true });
				}
			}
		},
		/**
		 * Reset state in every modules
		 * @param {object} commit
		 * @param {object} state
		 * @param {object} store
		 */
		reset({commit, state}) {
			const modules = Object.keys(state);

			for (let i = 0; i < modules.length; i++) {
				const module = modules[i];
				if (Object.prototype.hasOwnProperty.call(state[module], 'data')) {
					commit(`${module}/reset`, null, {root: true});
				}
			}
		}
	}
});

export default RootStore;
