import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import beforeEach from './beforeEach';

Vue.use(Router);

/**
 * Create all the routes for components to render 
 * @return {object} router
 */
const createRouter = function() {
	const router = new Router({
		mode: 'history',
		routes: routes
	});

	/**
	* Check each route change
	*/
	router.beforeEach(async(to, from, next) => {
		const beforeEachPath = await beforeEach({
			to,
			from,
			router
		});

		if (beforeEachPath) {
			next({path: beforeEachPath});
		} else {
			next();
		}
	});

	return router;
};

export {createRouter};
