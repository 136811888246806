
/**
 * @class NoteStore
 * 
 * Used for logging user actions
 */
import axios from '@/lib/Axios';
import _get from 'lodash/get';

const baseURL = '/api/rdap/external';

/**
 * Initial log state
 * @return {object} state
 */
const initialState = () => ({
	data: null,
	errors: null,
	acceptedTerms: false
});

const NoteStore = () => ({
	namespaced: true,
	state: initialState(),
	mutations: {
		/**
		 * Update the
		 * @param {object} state 
		 * @param {pbject} payload 
		 */
		updated(state, payload) {
			state.data = payload;
			state.errors = null;
		},
		/**
		 * Set errors
		 * @param {object} state
		 * @param {object} payload
		 */
		error(state, payload) {
			state.errors = _get(payload, 'response.data.response_text', 'Unknown error');
		},
		/** 
		 * Clear errors
		 * @param {object} state 
		 */
		clearErrors(state) {
			state.errors = null;
		},
		/** 
	 * Reset state
	 * @param {object} state 
	 */
		reset(state) {
			Object.assign(state, initialState());
		}
	},
	actions: {
		/**
		 * Log agreement to terms and service
		 * @param {Object} data 
		 * @param {string} data.text   Current username
		 * @param {string} data.reason_created      Reason of action being logged
		 * @param {string} data.tags   Tag the type of log
		 */
		async create({commit, state, rootState, rootGetters}, {text, reason_created, tags}) {
			const name = _get(rootGetters, 'user/username');

			return axios
				.post(`${baseURL}/users/${name}/notes`, {
					text,
					reason_created,
					tags
				}, {
					headers: _get(rootState, 'user.headers'),
					isLoading: true
				})
				.then((res) => {
					commit('updated', res);
				})
				.catch((err) => {
					commit('error', err);
				});
		},
		/**
	 * Clear errors
	 * @param {object} commit
	 */
		clearErrors({commit}) {
			commit('clearErrors');
		}
	}
});

export default NoteStore;
