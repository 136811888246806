import _get from 'lodash/get';
import Cookie from 'tucows-ui-misc/src/Cookie';

/**
 * Check whether cookies have AUTH_HEADERS in cookie
 * @param {object} store 
 * @param {object} router 
 *  @param {object} ssrContext 
 */
export default function({store, router, ssrContext}) {
	const AUTH_HEADERS = 'AUTH_HEADERS';

	// Request from server side then grab cookie from request, else grab from document.cookies
	const cookies = ssrContext ? _get(ssrContext, `req.cookies.${AUTH_HEADERS}`) : Cookie.get('AUTH_HEADERS');
	let headers = null;

	if (cookies) {
		try {
			headers = JSON.parse(cookies);
		} catch (e) {
			console.log(e);
			headers = null;
		}
	}

	store.commit('user/setHeaders', headers);
}
