/**
 * @class WhoisContactStore
 * Contact domain owner with email
 */
import _get from 'lodash/get';
import axios from '@/lib/Axios';

/**
 * Initial state of whois contact
 * @return {object} whois store state
 */
const initialState = () => ({
	data: null,
	errors: null
});

const getters = {
	domain: (state) => _get(state, 'data.domain'),
	brand: (state) => _get(state, 'data.brand', ''),
	registrar: (state) => _get(state, 'data.registrar', ''),
	dpo: (state) => _get(state, 'data.dpo', '')
};

const mutations = {
	/**
	 * Set domain and brand
	 * @param {object} state	Current state
	 * @param {object} payload	Reponse payload
	 */
	updateDetails(state, payload) {
		state.data = _get(payload, 'data.object_data');
	},
	/**
	 * Sent contact email
	 * @param {object} state	Current state
	 */
	sent(state){
		state.errors = null;
	},
	/**
	 * Error
	 * @param {object} state	Current state
	 * @param {object} payload	Response data
	 */
	error(state, payload) {
		let responseText = _get(payload, 'response.data.response_text', 'A problem has occurred. Please wait a few minutes and then try again.');
		state.errors = responseText;
	},
	/**
	 * Clear errors
	 * @param {object} state	Current state
	 */
	clearErrors(state) {
		state.errors = null;
	},
	/**
	 * 
	 * Reset state
	 * @param {object} state	Current state
	 */
	reset(state){
		state.errors =  null;
	}
};

const actions = {
	/**
	 * Send email request to contact whois domain owner
	 * @param {string} brand						Brand name
	 * @param {string} token						Token id
	 * @param {string} body							Email body
	 * @param {string} subject						Email subject
	 * @param {string} sender_email					Sender's email
	 * @param {string} sender_name					Sender's full name
	 * @param {string} domain						Domain name
	 * @return {Promise} Email sent
	 */
	send({commit}, {brand, token, form}) {
		const {body, subject, sender_email, sender_name, domain, recaptcha} = form;

		const data = {
			'body': body,
			'subject': subject,
			'sender_email': sender_email,
			'sender_name': sender_name,
			'domain': domain,
			'recaptcha': recaptcha
		};

		return axios.post(`/api/rdap/no-auth/email/${brand}/send/${token}`, data, {
			isLoading: true,
			noAuth: true
		})
			.then((res) => {
				commit('sent', res);
			})
			.catch((err) => {
				commit('error', err);
			});
	},
	/**
	 * Get the brand and email from token to render contact form
	 * @param {string} token	Token id
	 * @return {Promise} Information retrieved
	 */
	get({commit}, {token}) {
		return axios.get(`/api/rdap/no-auth/email/token_details/${token}`, {
			isLoading: true,
			noAuth: true
		})
			.then((res) => {
				commit('updateDetails', res);
			})
			.catch((err) => {
				commit('error', err);
			});
	},
	/**
	 * Clear errors
	 * @param {object} commit
	 */
	clearErrors({commit}) {
		commit('clearErrors');
	}
};


const WhoisContactStore = () => ({
	namespaced: true,
	state: initialState(),
	mutations: mutations,
	actions: actions,
	getters: getters
});

export default WhoisContactStore;
