import 'es6-promise/auto';
import Vue from 'vue';
import App from './App.vue';
import {createRouter} from './router';
import {createStore} from './store';
import {sync} from 'vuex-router-sync';
import i18n from '@/lib/i18n';
import authenticate from '@/lib/authenticate';
import axios from '@/lib/Axios';

// Support for IE
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/**
 * Create application with store, router and components for the web application to run
 * @param {object} ssrContext   Context
 */
const createApp =  function createApp(ssrContext) {
	// add i18n access to Vue
	Vue.prototype.i18n = i18n;
	Vue.use(i18n);

	// create router instance
	const router = createRouter();
	const store = createStore();

	sync(store, router);

	// Authenticate user by cookie
	authenticate({
		store,
		router,
		ssrContext: ssrContext
	});

	axios.store = store;
	axios.router = router;
	axios.context = ssrContext;

	const app = new Vue({
		router,
		components: {App},
		template: '<App/>',
		store,
		ssrContext
	});

	return {
		app,
		router,
		store
	};
};

export {createApp};
