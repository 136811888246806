import Vue from 'vue';
import Vuex from 'vuex';
import appConfig from './appConfig';
import common from './common';
import domain from './domain';
import forgot from './forgot';
import note from './note';
import root from './root';
import user from './user';
import whoisContact from './whoisContact';
import report from './report';

Vue.use(Vuex);

/**
 * Create all the stores and modules for api and components
 * @return {object} stores
 */
const createStore = function() {
	return new Vuex.Store({
		...root(),
		modules: {
			appConfig: appConfig(),
			common: common(),
			domain: domain(),
			forgot: forgot(),
			note: note(),
			user: user(),
			whoisContact: whoisContact(),
			report: report()
		}
	});
};

export {createStore};
