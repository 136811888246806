var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "single notFound" }, [
    _c("section", [
      _c("h1", [_vm._v("404")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n\t\t\t" +
            _vm._s(
              _vm.i18n.t("Sorry, the page you are looking for can't be found.")
            ) +
            "\n\t\t"
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }