
/**
 * @class ForgotStore
 * Password recovery
 */
import _get from 'lodash/get';
import axios from '@/lib/Axios';

/**
 * Initial store when forgot store is created to hold username and errors from api calls
 * @return {object}  store
 */
const initialState = () => ({
	username: null,
	errors: null
});

const getters = {username: (state) => _get(state, 'username')};

const mutations = {
	/**
	 * Sent password reset email
	 * @param {*} state 
	 * @param {*} payload 
	 */
	sent(state, payload){
		state.errors = null;
		state.email = _get(payload, 'data.object_data.username');
	},
	/**
	 * Error
	 * 
	 * @param {object} state 
	 * @param {object} payload
	 */
	error(state, payload) {
		let responseText = _get(payload, 'response.data.response_text', 'Unknown error');
		state.errors = responseText;
	},
	/**
	 * Clear errors
	 */
	clearErrors(state) {
		state.errors = null;
	},
	/**
	 * 
	 * Reset state
	 * @param {object} sta te 
	 */
	reset(state){
		state.errors =  null;
	}
};
const actions = {
	/**
	 * Send a password reset request
	 * @param {string} username Username
	 */
	send({commit}, {username}) {
		const data = {name: username};

		return axios.post(`/api/rdap/no-auth/external/users/requestpasswordreset`, data, {noAuth: true})
			.then((res) => {
				commit('sent', res);
			})
			.catch((err) => {
				commit('error', err);
			});
	},
	/**
	 * Clear errors
	 * @param {object} commit
	 */
	clearErrors({commit}) {
		commit('clearErrors');
	}
};


const ForgotStore = () => ({
	namespaced: true,
	state: initialState(),
	mutations: mutations,
	actions: actions,
	getters: getters
});

export default ForgotStore;
