import _get from 'lodash/get';
import client from './metaTagsClient';
import server from './metaTagsServer';
/**
 * @function metaTags
 * 
 * Set meta tags on server side
 * 
 * @param {object} to		To the next route
 * @param {object} from		From a route
 *  @param {object} router	Router
 */
export default function({to, from, router}) {
	const isServer = _get(router, 'app.$options.ssrContext');
	const method = isServer ? server : client;
	return method({
		to,
		from,
		router
	});
}
