<template>
  <div v-if="show" class="notification success">
    <div
      v-for="(message, index) in messageList"
      :key="`message${index}`"
      class="message"
    >
      {{ message }}
    </div>
    <button aria-label="Close" class="close material-icons" @click="close">
      close
    </button>
  </div>
</template>

<script>
/**
 * @class success messages
 *
 * Display success messages as a notification on the top of page
 */
import { mapActions, mapGetters } from "vuex";
import _get from "lodash/get";

export default {
  name: "SuccessMessage",
  /**
   * @ignore
   */
  data() {
    return { messageList: this.successMessages };
  },
  computed: {
    ...mapGetters({ successMessages: "successMessages" }),
    /**
     * Whether there are success messages
     * @return {boolean}
     */
    show() {
      const length = _get(this.messageList, "length");
      return Boolean(length);
    },
  },
  watch: {
    /**
     * Watch for changes in success messages
     */
    successMessages(newVal) {
      this.messageList = newVal;
    },
  },
  methods: {
    ...mapActions({ clearSuccessMessages: "clearSuccessMessages" }),
    /**
     * Close success popup and clear all messages
     */
    close() {
      this.clearSuccessMessages();
    },
  },
};
</script>

<style lang="less" scoped>
@import "common";

.notification {
  position: fixed;
  top: 30%;
  right: 0;
  left: 0;
  z-index: 1000;
  width: 70%;
  max-width: 54.857em; //768px;
  padding: 0.857em; // 12px
  margin: 0 auto;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: @black;
  background: @white;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);

  &.success {
    padding: 1em;
    border: 4px solid rgb(44, 226, 44);
  }

  .message {
    padding: 0 1em;
    font-size: 14px;
    border-radius: 4px;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    color: @border-colour;
  }
}
</style>
