/**
 * @function metaTags
 * 
 * Set meta tags on document head on client side
 * 
 * @param {object} to		To the next route
 */
export default function({to}) {
	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll('[data-head-meta]')).forEach((el) => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) {
		return;
	}

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags.map((tagDef) => {
		const tag = document.createElement('meta');

		Object.keys(tagDef).forEach((key) => {
			tag.setAttribute(key, tagDef[key]);
		});

		// We use this to track which meta tags we create, so we don't interfere with other ones.
		tag.setAttribute('data-head-meta', '');

		return tag;
	})
	// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));
}
