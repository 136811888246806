/**
 * @class DomainStore
 *
 * Retrieve rdap information on domains, brands, and brand settings
 */
import axios from '@/lib/Axios';
import _get from 'lodash/get';
import _set from 'lodash/set';
import Vue from 'vue';
import _assignIn from 'lodash/assignIn';

const baseURL = '/api/rdap/auth';

/**
 * Return initial state.
 */
const initialState = () => ({
	data: null,
	errors: null
});

const getterMethods = {
	/**
	 * @function entities
	 * @description
	 * Get a list of formatted users (entities) in the contact list (vCardArray or nested entities)
	 *
	 * From : rdap rfc format (https://tools.ietf.org/html/rfc7483#page-16)
	 *		["version", {}, "text", "4.0"],
	 *		["fn", {}, "text", "Whois Agent"],
	 *		["kind", {}, "text", "individual"],
	 *		["lang", {}, "language-tag", "en"],
	 *		["tel", {"type": ["voice"]}, "uri", ""]
	 *
	 *
	 * To: User object
	 * {
	 *  'version': '4.0',
	 *  'fn': 'Whois Agent',
	 *  'kind': 'individual'
	 * }
	 */
	entities: (state, getters) => (entities, formattedEntities = [], roles = []) => {
		// Loop through each entity
		for (let i = 0; i < entities.length; i++) {
			const entity = _get(entities, i);
			const additionalRoles = roles.concat(_get(entity, 'roles'));
			const additionalEntities = _get(entity, 'entities');
			const userInformation = _get(entity, ['vcardArray', 1]);

			// // If there is no user or nested entities then go to the next
			if (!userInformation && !additionalEntities) {
				continue;
			}

			const user = {};

			// Map id and value. eg. fn: 'John Smith'
			userInformation && userInformation.forEach((item) => {
				const id = _get(item, '0');
				const value = _get(item, '3');

				if (!value) {
					return;
				}

				const type = _get(item, '1.type.0');

				/** If type is supplied then make it an object.
				 *  Eg.
				 * 	tel =  type: fax
				 * 	tel = type: voice
				 * */
				if (type) {
					_set(user, [id, type], value);
				} else {
					user[id] = value;
				}
			});

			// If user does not have role, then look at top level entity. eg. registrar + abuse role
			if (user && !user.role) {
				user.role = additionalRoles.join(' ');
			}

			formattedEntities.push(user);

			// Look at nested entities
			if (additionalEntities) {
				getters.entities(additionalEntities, formattedEntities, additionalRoles);
			}
		}

		return formattedEntities;
	}
};

const mutations = {
	/**
	 * Registrant data information, similar to whois information
	 * @param {object} state
	 * @param {object} payload
	 */
	registrationData(state, payload) {
		Vue.set(state, 'data', _get(payload, 'data'));
		state.errors = null;
	},
	/**
	 * Domain name and brand
	 * @param {object} state
	 * @param {object} payload
	 */
	domainData(state, payload) {
		Vue.set(state, 'domain', _get(payload, 'domain'));
		Vue.set(state, 'brand', _get(payload, 'brand'));
	},
	/**
	 * Set errors
	 * @param {object} state
	 * @param {object} payload
	 */
	error(state, payload) {
		state.data = null;
		state.errors = _get(payload, 'response.data.response_text', 'Unknown error');
	},
	/**
	 * Clear errors
	 * @param {object} state
	 */
	clearErrors(state) {
		state.errors = null;
	},
	/**
	 * Reset state
	 * @param {object} state
	 */
	reset(state) {
		_assignIn(state, initialState());
	}
};


const actions = {
	/**
	 * Get domain suggestions
	 * @param {object} commit
	 * @param {object} brand   Brand name
	 * @param {object} domain  Domain Name
	 */
	getRegistrationData({commit, rootState}, {brand, domain, reason, opt_out, reason_for_not_disclosing}) {
		// check if domain is valid
		const domainRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
		if(!domainRegex.test(domain)){
			commit('error', {
				response: {
					data:{response_text:"Please enter a valid domain name."}
				}});
			return
		}

		const url = `${baseURL}/${brand}/domain/${domain}`;
		const version = 2;

		return axios.get(url, {
			isLoading: true,
			params: {
				reason,
				opt_out,
				reason_for_not_disclosing,
				version
			},
			headers: _get(rootState, 'user.headers')
		})
			.then((res) => {
				commit('registrationData', res);
				commit('domainData', {
					"domain": domain,
					"brand": brand
				})
			})
			.catch((err) => {
				commit('error', err);
			});
	}
};

const DomainStore = () => ({
	namespaced: true,
	state: initialState(),
	actions,
	mutations,
	getters: getterMethods
});

export default DomainStore;
