/* eslint-disable */
const NotFound = () =>
		import(/* webpackChunkName: "group-site" */ '../pages/NotFound.vue');
const Search = () =>
		import(/* webpackChunkName: "search" */ '../pages/Search/Search.vue');
const Login = () =>
		import(/* webpackChunkName: "login" */ '../pages/Login/Login.vue');
const Forgot = () =>
		import(/* webpackChunkName: "forgot" */ '../pages/Forgot/Forgot.vue');
const PasswordNewReset = () =>
		import(/* webpackChunkName: "passwordNewReset" */ '../pages/Password/PasswordNewReset.vue');
const PasswordChange = () =>
		import(/* webpackChunkName: "passwordChange" */ '../pages/Password/PasswordChange.vue');
const WhoisContact = () =>
		import ( /* webpackChunkName: "whois-contact" */ '../pages/WhoisContact/WhoisContact.vue');
const WhoisContactSent = () =>
		import ( /* webpackChunkName: "whois-contact" */ '../pages/WhoisContact/WhoisContactSent.vue');
const Terms = () =>
		import('../pages/Terms/Terms.vue')
const AccountInfo = () =>
		import(/* webpackChunkName: "AccountInfo" */ '../pages/AccountInfo/UserAccountInfo.vue');
/* eslint-enable */

/**
 * A list of routes
 *
 * Meta information:
 * 	title: title of the page
 * 	requireAuth: Only visible when user is authenticated
 * 	public: Only visible to user unauthenticated
 *
 * Prop information:
 * 	firstTimeUser: determines if user is on the application for the first time
 * */
import i18n from '@/lib/i18n';

const routes = [
	{
		path: '/',
		component: Search,
		meta: {requireAuth: true}
	},
	{
		path: '/search',
		component: Search,
		meta: {
			requireAuth: true,
			title: i18n.t('Tiered Access - Search'),
		}
	},
	{
		path: '/login',
		component: Login,
		meta: {title: i18n.t('Tiered Access')}
	},
	{
		path: '/forgot',
		component: Forgot,
		meta: {title: i18n.t('Tiered Access - Reset Your Password')}
	},
	{
		path: '/password-new',
		component: PasswordNewReset,
		meta: {
			title: i18n.t('Tiered Access - First Time Password'),
			metaTags: [
				{
					name: 'robots',
					content: 'noindex'
				} // Prevent from appearing on google search results
			]
		},
		props: {firstTimeUser: true}
	},
	{
		path: '/password-new/:username/:token',
		component: PasswordNewReset,
		meta: {
			title: i18n.t('Tiered Access - First Time Password'),
			metaTags: [
				{
					name: 'robots',
					content: 'noindex'
				} // Prevent from appearing on google search results
			]
		},
		props: {firstTimeUser: true}
	},
	{
		path: '/password-reset',
		component: PasswordNewReset,
		meta: {
			title: i18n.t('Tiered Access - Reset Password'),
			metaTags: [
				{
					name: 'robots',
					content: 'noindex'
				} // Prevent from appearing on google search results
			]
		}
	},
	{
		path: '/password-reset/:username/:token',
		component: PasswordNewReset,
		meta: {
			title: i18n.t('Tiered Access - Reset Password'),
			metaTags: [
				{
					name: 'robots',
					content: 'noindex'
				} // Prevent from appearing on google search results
			]
		}
	},
	{
		path: '/password-change',
		component: PasswordChange,
		meta: {
			requireAuth: true,
			title: i18n.t('Tiered Access - Change Your Password')
		}
	},
	{
		path: '/user-account-info',
		component: AccountInfo,
		meta: {
			requireAuth: true,
			title: i18n.t('Tiered Access - My Account Info')
		}
	},
	{
		path: '/contact/sent',
		component: WhoisContactSent,
		meta: {
			alwaysAvailablePage: true,
			title: i18n.t('Tiered Access - Whois Contact Sent'),
			name: 'whoiscontact'
		}
	},
	{
		path: '/contact/:token',
		component: WhoisContact,
		meta: {
			alwaysAvailablePage: true,
			title: i18n.t('Tiered Access - Whois Contact'),
			name: 'whoiscontact'
		}
	},
	{
		path: '/terms',
		component: Terms,
		meta: {
			alwaysAvailablePage: true,
			title: i18n.t('Tiered Access - Tucows Tiered Access Compliance and Operations System Terms of Service'),
			name: 'terms'
		}
	},
	{
		path: '*',
		component: NotFound,
		meta: {alwaysAvailablePage: true}
	}
];

export default routes;
