import axios from '@/lib/Axios';
import _get from 'lodash/get';

/**
 * @class AppConfigStore
 * Represent the configuration of the current application
 */
const AppConfigStore = () => ({
	namespaced: true,
	/**
	 * State of current store 
	 * @return {object} state
	 */
	state() {
		return {
			data: null,
			error: null
		};
	},
	getters: {
		/*
		 * Get the recaptcha site key
		 * @param state      State of store
		 * @return {object}  key
		 * */
		recaptchaKey: (state) => _get(state, 'data.recaptchaKey'),
		/**
		 * Return the hashmap of feature flags for environment
		 * @param state      State of store
		 * @return {object} feature flags
		 */
		features: (state) => _get(state, 'data.features'),
		/**
		 * Whether environment has feature
		 * @param state      State of store
		 * @param getters    Get data
		 * @return {boolean} feature present
		 */
		hasFeature: (state, getters) => (featureName) => {
			const features = _get(getters, 'features');

			// No feature name defined, then display component. eg. Login page
			if (!featureName) {
				return true;
			}

			// If features are present verify featureName is enable, else feature is disable
			return features ? _get(features, featureName, true) : false;
		}
	},
	actions: {
		/**
		 * Get app config data, such as recaptcha key
		 * @param commit     Commit changes to state
		 * @param getters    Get data
		 * @returns {object} configuration
		 */
		get({commit, state}) {
			const data = _get(state, 'data');

			// If data exists,then no need make api call
			if (data) {
				return Promise.resolve(data);
			}

			return axios.get('/config').then((res) => {
				const config = _get(res, 'data');
				commit('update', config);
				return config;
			}).catch((err) => {
				commit('error', err);
				return err;
			});
		}
	},
	mutations: {
		/**
		 * Update config
		 * @param {object} state
		 * @param {object} payload
		 */
		update(state, payload) {
			state.data = payload;
			state.error = null;
		},
		/**
		 * Add error message to error state
		 * @param {object} state
		 * @param {object} payload
		 */
		error(state, payload) {
			state.error = payload;
		},
		/**
		 * Clear Errors
		 * @param {object} state 
		 */
		clearErrors(state) {
			state.error = null;
		},
		/**
		 * Reset error
		 * @param {object} state 
		 */
		reset(state) {
			state.error = null;
		}
	}
});

export default AppConfigStore;
