import i18n from 'tucows-ui-misc/src/i18n';


i18n.init({
	// Allow colon and periods for gettext style keys
	nsSeparator: ':::',
	keySeparator: '::',
});

export default i18n;
