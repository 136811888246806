import requireAuth from './requireAuth';
import metaTags from './metaTags';

/**
 * Check each router path 
 * 1. Feature flag is turned on or off
 * 2. User is authenticated
 * @param {object} to       To route 
 * @param {object} from     From route
 * @param {object} router   Router
 */
export default async function({to, from, router}) {
	// Add meta when applicable
	metaTags({
		to,
		from,
		router
	});

	const requireAuthPath = await requireAuth({
		to,
		from,
		router
	});

	if (requireAuthPath) {
		return requireAuthPath;
	}

	return;
}
