import _get from 'lodash/get';


/**
 * Html encode the data
 * @param {string} s  Html
 * @return {string}  html encoded
 */
const encodeHTMLEntities = function(s) {
	return s.replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
};

/**
 * @function metaTags
 * 
 * Set meta tags on server side
 * 
 * @param {object} to		To the next route
 * @param {object} router	Router
 */
export default function({to, router}) {
	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

	if (_get(router, 'app.$options.ssrContext')) {
		router.app.$options.ssrContext.meta = '';
	}

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) {
		return;
	}

	let allMetaTags = '';
	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags.map((tagDef) => {
		let attributes = '';

		Object.keys(tagDef).forEach((key) => {
			attributes += ` ${key}='${encodeHTMLEntities(tagDef[key])}' `;
		});

		allMetaTags += `<meta data-head-meta ${attributes} >`;
	});

	if (_get(router, 'app.$options.ssrContext')) {
		router.app.$options.ssrContext.meta = allMetaTags;
	}
}
