<template>
	<div id="app">
		<Error />
		<SuccessMessage />
		<Success
			:show="showSuccessAlert"
			:success-message="successMessageAlert"
			@update:show="handleChangeSuccess"
		/>
		<Loading
			:show="showLoading"
			@update:show="handleChangeLoading"
		/>
		<template>
			<AppHeader />
			<router-view v-if="showFeature" />
			<NotFound v-if="showNotFound" />
		</template>
	</div>
</template>

<script>
import AppHeader from '@/components/Header/AppHeader.vue';
import Error from '@/components/Error.vue';
import SuccessMessage from '@/components/SuccessMessage.vue';
import Success from 'tucows-ui-components/src/Alert/Success.vue';
import Loading from 'tucows-ui-components/src/Alert/Loading.vue';
import NotFound from '@/pages/NotFound.vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import _get from 'lodash/get';
import Filters from 'tucows-ui-misc/src/Filters';

export default {
	name: 'App',
	components: {
		AppHeader,
		Error,
		SuccessMessage,
		Loading,
		NotFound,
		Success
	},
	data: () => ({
		showSuccessAlert: false,
		successMessageAlert: null,
		showFeature: false,
		showNotFound: false
	}),
	computed: {
		...mapGetters({
			isLoggedIn: 'user/isLoggedIn',
			username: 'user/username',
			hasFeature: 'appConfig/hasFeature',
			hasAcceptedTerms: 'user/hasAcceptedTerms'
		}),
		...mapState({
			user: (state) => _get(state, 'user.details'),
			showSuccess: (state) => _get(state, 'common.success'),
			showLoading: (state) => Filters.isWindow() && _get(state, 'common.loading'),
			successMessage: (state) => _get(state, 'common.successMessage')
		})
	},
	watch: {
		/**
		 * Monitor changes in showSuccess to show success component
		 */
		showSuccess(newVal) {
			this.showSuccessAlert = newVal;
		},
		/**
		 * Display the message in application
		 * @param {string} newVal   Message
		 */
		successMessage(newVal) {
			this.successMessageAlert = newVal;
		},
		/**
		 * Watch for changes in route and check feature flag
		 */
		'$route'() {
			this.checkFeatureFlag();
		}
	},
	/**
	 * Client side check if user information
	 */
	mounted() {
		return this.verify();
	},
	methods: {
		...mapActions({
			'hideSuccess': 'common/hideSuccess',
			'getUserByName': 'user/getUserByName',
			'getConfig': 'appConfig/get',
			'logout': 'user/logout'
		}),
		/**
		 * Check the state of show success
		 * @param {boolean} newVal   show success
		 */
		handleChangeSuccess(newVal) {
			// Hide success
			if (!newVal) {
				this.hideSuccess();
			}

			this.showSuccessAlert = newVal;
		},
		/**
		 * Check the state of show loading
		 */
		handleChangeLoading(newVal) {
			// Hide success
			if (newVal) {
				this.hideSuccess();
			}
		},
		/**
		 * If no feature flag was defined in route show the component,
		 * else get config to check if feature is enable for component
		 */
		verify() {
			const featureName = _get(this.$route, 'meta.name');
			// If no feature flag was defined, then show component. eg. 404 pages
			if (!featureName) {
				this.showFeature = !featureName;
				return Promise.resolve();
			}

			return this.getConfig().then(this.checkFeatureFlag);
		},
		/**
		 * If environment has feature flag show component, else redirect to 404
		 */
		checkFeatureFlag() {
			const featureName = _get(this.$route, 'meta.name');
			const showFeature = this.hasFeature(featureName);

			// Not found component should be shown when feature is disabled
			this.showNotFound = !showFeature;

			// Show component when feature is enabled
			this.showFeature = showFeature;
			return;
		}
	}
};
</script>

<style lang="less">
	@import 'theme';
	@import 'tucows-ui-components/src/styles/bundle';
	@import 'common';

	* {
		box-sizing: border-box;
		line-height: normal;
	}

	#app {
		position: relative;
		width: 100%;
		height: 100%;
		min-height: 100%;
		padding-top: @headerHeight;
	}
</style>
