import _get from 'lodash/get';
/**
 * @function requireAuth
 * 
 * Determine if route can load or redirect
 * 1. alwaysAvailablePage is true, then render current url
 * 2. 
 * @param {object} to		To the next route
 * @param {object} from		From a route
 * @param {object} router	
 */
export default async function({to, from, router}) {
	const alwaysAvailablePage = _get(to, 'meta.alwaysAvailablePage');

	// Page can always render. eg. Contactability page can be accessed by any user (authenticated or UNauthenticated)
	if (alwaysAvailablePage) {
		return;
	}

	const requireAuth = _get(to, 'meta.requireAuth');
	const store = _get(router, ['app', '$options', 'store']);
	const isLoggedIn = _get(store, ['getters', 'user/isLoggedIn']);

	// Verify user has accepted terms before going to different route
	if (requireAuth && isLoggedIn){
		await store.dispatch('user/getUserByName', {isLoading: true}, {root: true});
		if (!_get(store, ['getters', 'user/hasAcceptedTerms'])) {
			await store.dispatch('user/logout', null, {root: true});
			return '/login';
		}
	}

	// User is not logged in, then redirect to login page
	if (!isLoggedIn && requireAuth) {
		return '/login';
	}

	// User is logged in but tries to reach login -> redirect to search
	if (isLoggedIn && to.path == '/login') {
		return '/search';
	}

	// Load existing route
	return;
}
